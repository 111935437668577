'use strict';

function linkedin(opt) {
    var redirect_url = opt.redirect_url || (location.protocol + '//' + location.host + '/app.php/oauth/linkedin');
    var callback_url = opt.callback_url || (location.protocol + '//' + location.host + '/oauth/linkedin');

    var url = redirect_url + '?callback_url=' + encodeURIComponent(callback_url) + '&site_id=' + opt.site_id;

    opt.listener.addEventListener('click', function () {
        window.location.href = url;
    });
}

function facebook(opt) {
    var type; // login/bind

    function statusChangeCallback(response) {
        if (response.status === 'connected') {
            $.post('/oauth/facebook', { auth: response.authResponse }, function(res) {
                if (!res.status) {
                    _global.prompt(res.msg, false, 3);
                    return;
                }

                // 跳转至服务器返回的地址
                if (res.redirect_url) {
                    location.href = res.redirect_url;
                    return;
                }
                // 跳转至地址栏中的 url_direct_to
                if (location.search) {
                    var query = location.search.substring(1).split('&');
                    for (var i = 0; i < query; i++) {
                        var param = query[i].split('=');
                        if (param[0] == 'url_direct_to' && param[1]) {
                            location.href = param[1];
                            return;
                        }
                    }
                }

                // 跳转至首页
                location.href = '/';
            }, 'json');
        } else {
            FB.login(function (response) {
                if (response.status === 'connected') {
                    statusChangeCallback(response);
                }
            }, { scope: 'public_profile,email' });
        }
    }

    if (typeof FB == 'undefined') {
        var script = document.createElement('script');
        script.src = '//connect.facebook.net/en_US/sdk.js';
        script.addEventListener('load', function() {
            FB.init({
                appId: '516210349062568',
                cookie: true,  // enable cookies to allow the server to access the session
                xfbml: true,  // parse social plugins on this page
                version: 'v6.0' // use graph api version 2.8
            });
        });
        script.addEventListener('error', function(error) {
            // @TODO
        });
        document.querySelector('body').append(script);
    }
    
    opt.listener.addEventListener('click', function () {
        type = opt.type || this.dataset.type || '';

        if (typeof FB == 'undefined') {
            _global.prompt('Please confirm you can access facebook', false, 3);
        } else {
            FB.getLoginStatus(function (response) {
                statusChangeCallback(response);
            });
        }
    });
}

function twitter(opt) {
    hello.init({
        twitter: 'OizPtdbVdOevvntI96lWnpT8W',
    }, {
        // redirect_uri:'/oauth/twitter_login', //代理后的重定向路径，可不填
        oauth_proxy: 'https://auth-server.herokuapp.com/proxy' //这里使用默认的代理
    });

    opt.listener.addEventListener('click', function () {
        var type = opt.type || this.dataset.type || '';
        // Twitter instance
        var t = hello('twitter');
        // Login
        t.login().then(function (r) {
            return t.api('/me');
        }).then(function (p) {
            console.log(p);
            return;

            if (type == 'bind') {
                self.location = 'http://www.aconf.org/u/account_bind_twitter?code=' + p.id + '&name=' + p.name + '&figureurl=' + p.profile_image_url;
            } else {
                self.location = 'http://www.aconf.org/oauth/twitter_login?code=' + p.id + '&name=' + p.name + '&figureurl=' + p.profile_image_url;
            }
        }, function(res) {
            console.log(res);
            
        });
    });
}
function qq(opt) {
    var redirect_url = opt.redirect_url || (location.protocol + '//' + location.host + '/app.php/oauth/qq');
    var callback_url = opt.callback_url || (location.protocol + '//' + location.host + '/oauth/qq');

    var url = redirect_url + '?callback_url=' + encodeURIComponent(callback_url);

    opt.listener.addEventListener('click', function () {
        window.location.href = url;
    });
}
function weixin(opt) {
    var redirect_url = opt.redirect_url || (location.protocol + '//' + location.host + '/app.php/oauth/weixin');
    var callback_url = opt.callback_url || (location.protocol + '//' + location.host + '/oauth/weixin');

    var url = redirect_url + '?callback_url=' + encodeURIComponent(callback_url);

    opt.listener.addEventListener('click', function () {
        window.location.href = url;
    });
}
var oauth2 = function(opts) {
    for (const key in opts) {
        if (opts.hasOwnProperty(key)) {
            switch (key) {
                case 'linkedin':
                    linkedin(opts[key]);
                    break;
                case 'facebook':
                    facebook(opts[key]);
                    break;
                case 'twitter':
                    twitter(opts[key]);
                    break;
                case 'qq':
                    qq(opts[key]);
                    break;
                case 'weixin':
                    weixin(opts[key]);
                    break;
            }
        }
    }
};

window.AconfGlobal = window.AconfGlobal || {};
window.AconfGlobal.oauth2 = oauth2;

export default oauth2;