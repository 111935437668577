import oauth2 from '../../../global/components/base/oauth2';

$(function () {
    oauth2({
        qq: {
            listener: document.querySelector('#qq'),
            callback_url: 'https://www.aconf.cn/oauth/qq_login?redirect_url=' + (document.querySelector('#qq')?.attributes['redirect_url']?.value ?? ''),
        },
        weixin: {
            listener: document.querySelector('#weixin'),
            callback_url: 'https://www.aconf.cn/oauth/wx_login?redirect_url=' + (document.querySelector('#weixin')?.attributes['redirect_url']?.value ?? ''),
        },
        linkedin: {
            listener: document.querySelector('#linkedin'),
            site_id: 2,
        }
    });
    $('#email').on('keyup', function () {
        var value = $(this).val().trim();
        var regex = /^[0-9 ]+$/;
        if (regex.test(value)) {
            $('.phone_code').show();
            $('.send-btn.send-phone-btn').attr('style', 'display:block')
            $('.send-btn.send-email-btn').attr('style', 'display:none')
        } else {
            $('.phone_code').hide();
            $('.send-btn.send-phone-btn').attr('style', 'display:none')
            $('.send-btn.send-email-btn').attr('style', 'display:block')
        };
    });
    var init_iso_code = $(".country-code-select").val();
    var initCountryCodeSelect = function (obj) {
        $.getJSON('//file.aconf.org/public/data/country/' + window.AconfGlobal.lang + '.json', function (data, status) {
            if (status == 'success' && data) {
                for (var key in data) {
                    data[key].forEach(function (e) {
                        e.name = '+' + e.country_code + ' ' + e.name;
                    });
                };
                var hot_country = {};

                for (var key in window.AconfGlobal._global.M.hot_country_zh) {
                    hot_country['请选择手机区号'] = JSON.parse(JSON.stringify(window.AconfGlobal._global.M.hot_country_zh[key]));
                }

                for (var key in hot_country) {
                    if (hot_country.hasOwnProperty(key)) {
                        hot_country[key].shift();
                        hot_country[key].forEach(function (e) {
                            e.name = '+' + e.country_code + ' ' + e.name;
                        });
                    };
                };

                if (!obj) {
                    obj = '.country-code-select';
                };
                if (!(obj instanceof jQuery)) {
                    obj = $(obj);
                };

                obj.each(function (i, e) {
                    var select = $(e);
                    var initCode = init_iso_code ? init_iso_code : '';
                    if (select.data('initCode')) {
                        initCode = select.data('initCode');
                    };
                    $(e).html(get_option_string(hot_country, initCode) + get_option_string(data, initCode));
                });
            }
        });
    };
    var get_option_string = function (obj, initCode) {
        var html = '';
        $.each(obj, function (i, n) {
            html += '<optgroup label="' + i + '">';
            $.each(n, function (j, country) {
                if (initCode && (initCode == country.iso_code || initCode == country.country_code)) {
                    html += '<option country_id="' + country.country_id + '" selected="selected" value="' + country.country_code + '" country_code="' + country.country_code + '">' + country.name + '</option>';
                } else {
                    if (country.iso_code != 0) {
                        html += '<option country_id="' + country.country_id + '" value="' + country.country_code + '" country_code="' + country.country_code + '">' + country.name + '</option>';
                    } else {
                        html += '<option country_id="' + country.country_id + '" country_code="' + country.country_code + '">' + country.name + '</option>';
                    };
                };
            });
            html += '</optgroup>';
        });
        return html;
    };
    initCountryCodeSelect('.country-code-select', init_iso_code);

    $(".select-login-type>li").on('click', function () {
        var type = $(this).attr('data-type');
        $(this).addClass('active');
        $(this).siblings().removeClass('active');
        if (type == 'account') {
            $(".password-div").show();
            $(".vcode-div").hide();
            $('#verifyCode').removeAttr("va-required");
            if (!$("#password")[0].hasAttribute('va-required')) {
                $("#password").attr('va-required', '请输入密码');
            };
            $("input[name='login_type']").val('password');
        }
        if (type == 'verifyCode') {
            $(".password-div").hide();
            $(".vcode-div").show();
            $("#password").removeAttr("va-required");
            if (!$("#verifyCode")[0].hasAttribute('va-required')) {
                $("#verifyCode").attr('va-required', '请输入验证码');
            };
            $("input[name='login_type']").val('vcode');
        };
    });

    var check_cookie = function check_cookie(c_name) {
        if (document.cookie.length > 0) {
            var c_start = document.cookie.indexOf(c_name + "=");
            var c_end
            if (c_start != -1) {
                c_start = c_start + c_name.length + 1;
                c_end = document.cookie.indexOf(";", c_start);
                if (c_end == -1) {
                    c_end = document.cookie.length;
                }
                return unescape(document.cookie.substring(c_start, c_end));
            }
        }
        return "";
    };
    var check_Login_cookie = function check_Login_cookie() {
        var email_expire_second = check_cookie('login_expire_second');
        if (email_expire_second) {
            time($('.send-btn'), email_expire_second)
        }
    };
    check_Login_cookie()
    function set_cookie(c_name, second) {
        var exdate = new Date();
        exdate.setTime(exdate.getTime() + second * 1000);
        document.cookie = c_name + "=" + escape(second) + (second == null ? "" : ";expires=" + exdate.toGMTString());
    };
    function time(o, wait) {
        if (wait == 0) {
            o.removeAttr("disabled");
            o.html("发送验证码");
            wait = 60;
        } else {
            o.attr("disabled", true);
            o.html(wait + "S");
            wait--;
            set_cookie('login_expire_second', wait)
            setTimeout(function () {
                time(o, wait);
            }, 1000);
        }
    }

    $(".send-btn.send-email-btn").on('click', function () {
        var email = $("#email").val();
        if (email != '') {
            $('.send-btn.send-email-btn').attr('data-dialog-big', 'true')
        } else {
            return
        }
    });
    $(".send-btn.send-phone-btn").on('click', function () {
        var email = $("#email").val();
        if (email != '') {
            $('.send-btn.send-phone-btn').attr('data-dialog-big', 'true')
        } else {
            return
        }
    });
});